<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">

    <search-invoices :invoices="invoices" @add="addInvoiceSidebar" :isSidebarActive="showInvoiceSidebar"
                     @closeSidebar="showInvoiceSidebar = false"/>

    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Nueva nota de crédito de venta</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">

        <!--Sales-->
        <div>
          <vs-button @click="showInvoiceSidebar = true" :disabled="progress" class="w-full" color="danger"
                     type="border">
            Buscar comprobante
          </vs-button>
        </div>

        <!-- Client and Document -->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/2">
            <vs-input class="w-full" label="Comprobante*" v-model="document.invoice.code" disabled/>
          </div>
          <div class="vx-col w-1/2">
            <div>
              <vs-input class="w-full" label="Cliente*" v-model="document.client.businessName" disabled/>
            </div>
          </div>
        </div>

        <!-- Type -->
        <div class="mt-5">
          <label class="text-sm opacity-75">Motivo*</label>
          <v-select placeholder="Selecciona" @input="calculateTotal" :options="types"
                    :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.type" class="mb-4 md:mb-0" name="type" id="type"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('type')">{{ errors.first('type') }}</span>
        </div>

        <!--Divider-->
        <vs-divider class="mt-5"></vs-divider>

        <!---Currency, Decimals and IGV-->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/3">
            <div>
              <vs-input class="w-full" label="Moneda*" v-model="document.currency.value" disabled/>
            </div>
          </div>
          <div class="vx-col w-1/3">
            <div>
              <vs-input class="w-full" label="Forma de pago*" v-model="document.paymentMethod.value" disabled/>
            </div>
          </div>
          <div class="vx-col w-1/3">
            <div>
              <div>
                <vs-input class="w-full" label="IGV*" v-model="document.igvType" disabled/>
              </div>
            </div>
          </div>
        </div>

        <!---Regime and decimals--->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/2">
            <div>
              <vs-input class="w-full" label="Tipo de redondeo*" v-model="document.roundingType" disabled/>
            </div>
          </div>
          <div class="vx-col w-1/2">
            <div>
              <vs-input class="w-full" label="Tipo de regimen*" v-model="document.regimeType.label" disabled/>
            </div>
          </div>
        </div>
        <!--End-->

        <!--Observation-->
        <div class="mt-5">
          <vs-textarea v-validate="'max:255'" rows="2" name="observation" label="Observación"
                       v-model="document.observation"/>
          <span class="text-danger text-sm"
                v-show="errors.has('observation')">{{ errors.first('observation') }}</span>
        </div>
        <!--End-->

        <!--Divider-->
        <vs-divider class="mt-5"></vs-divider>

        <!--Added products-->
        <vs-table class="mt-8" :data="addedProducts">

          <template slot="thead">
            <vs-th>Descripción</vs-th>
            <vs-th>Nro. de Parte</vs-th>
            <vs-th>Precio ({{ document.currency ? document.currency.symbol : '' }})</vs-th>
            <vs-th>Cantidad</vs-th>
            <vs-th>Total</vs-th>
            <vs-th>Eliminar</vs-th>
          </template>

          <template slot-scope=" {data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

              <vs-td style=" width: 40%" :data="tr.description">
                <vs-textarea disabled rows="5" v-model="tr.description"></vs-textarea>
              </vs-td>

              <vs-td :data="tr.numberPart">
                <p>{{ tr.numberPart }}</p>
              </vs-td>

              <vs-td :data="tr.price">
                <p>
                  <vs-input style=" width: 60px" min="1"
                            :max="tr.price" type="number"
                            v-model.number="tr.price"/>
                </p>
              </vs-td>

              <vs-td :data="tr.quantity">
                <template>
                  <vs-input @blur="tr.quantity > tr.max ? tr.quantity = tr.max : '' " style=" width: 60px" min="1"
                            :max="tr.max" type="number"
                            v-model.number="tr.quantity"/>
                </template>
              </vs-td>

              <vs-td :data="tr.total">
                <template>
                  <p v-if="document.igvType === 'No incluye IGV'">
                    {{
                    Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: document.currency.code,
                    minimumFractionDigits: document.roundingType,
                    maximumFractionDigits: document.roundingType,
                    }).format(tr.price * tr.quantity)
                    }}
                  </p>
                  <p v-else>
                    {{
                    Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: document.currency.code,
                    minimumFractionDigits: document.roundingType,
                    maximumFractionDigits: document.roundingType,
                    }).format(tr.price * tr.quantity * 1.18)
                    }}
                  </p>
                </template>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <feather-icon class="ml-2" icon="TrashIcon"
                              svgClasses="w-5 h-5 hover:text-primary stroke-current"
                              @click.stop="deleteProduct(tr)"/>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <!---->

        <!--Divider-->
        <vs-divider class="mt-5"></vs-divider>

        <div class="vx-row">
          <div class="vx-col w-3/4"></div>
          <div class="vx-col w-1/4">
            <div class="flex flex-wrap items-center justify-between">
              <span class="font-bold">Sub total:</span>
              <span>
                {{
                Intl.NumberFormat('en-IN', {
                style: 'currency',
                currency: document.currency.code ? document.currency.code : 'PEN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                }).format(total / 1.18)
                }}
              </span>
            </div>
            <!--Divider-->
            <vs-divider class="mt-5"></vs-divider>
            <div class="flex flex-wrap items-center justify-between">
              <span class="font-bold">IGV:</span>
              <span>
                {{
                Intl.NumberFormat('en-IN', {
                style: 'currency',
                currency: document.currency.code ? document.currency.code : 'PEN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                }).format((total / 1.18) * 0.18)
                }}
              </span>
            </div>
            <!--Divider-->
            <vs-divider class="mt-5"></vs-divider>
            <div class="flex flex-wrap items-center justify-between">
              <span class="font-bold">Total:</span>
              <span>
                {{
                Intl.NumberFormat('en', {
                style: 'currency',
                currency: document.currency.code ? document.currency.code : 'PEN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
                }).format(total)
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </component>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Generar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style=" margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'
import vSelect from 'vue-select'
import SearchInvoices from '@/views/credits/SearchInvoices'

import { auth, db, FieldValue } from '@/firebase/firebaseConfig'
import axios from 'axios'

import departments from '../../data/ubigeo/departments.json'
import provinces from '../../data/ubigeo/province.json'
import districts from '../../data/ubigeo/district.json'
import ubigeo from '../../data/ubigeo/ubigeo.json'

const _ = require('lodash')

export default {
  name: 'NewCredit',
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    invoices: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect,
    SearchInvoices
  },
  mixins: [trimString],
  data() {
    return {
      document: {
        currency: {},
        client: {},
        paymentMethod: {},
        igvType: null,
        regimeType: {},
        invoice: {},
        observation: ''
      },
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      types: [
        {
          id: 1,
          label: 'ANULACIÓN DE LA OPERACIÓN'
        },
        {
          id: 2,
          label: 'ANULACIÓN POR ERROR EN EL RUC'
        },
        {
          id: 6,
          label: 'DEVOLUCIÓN TOTAL'
        },
        {
          id: 7,
          label: 'DEVOLUCIÓN POR ITEM'
        },
        {
          id: 10,
          label: 'OTROS CONCEPTOS'
        }
      ],
      // Sidebars
      showNewProviderSidebar: false,
      showAddressSidebar: false,
      showContactSidebar: false,
      showNewProductSidebar: false,
      showInvoiceSidebar: false,
      addedProducts: [],
      selected: null,
      total: 0,
      // Ubigeo
      departments: departments,
      provinces: provinces,
      districts: districts,
      ubigeo: ubigeo
    }
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        this.document = {
          currency: {},
          invoice: {},
          client: {},
          paymentMethod: {},
          igvType: null,
          regimeType: {}
        }
        this.addedProducts = []
        this.$validator.reset()
      }
    },
    addedProducts: {
      deep: true,
      handler() {
        this.calculateTotal()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid() {
      return !this.errors.any()
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Delete product
     */
    deleteProduct(product) {
      if (this.addedProducts.length !== 1) {
        const indexProduct = this.addedProducts.findIndex((p) => p.id === product.id)
        this.addedProducts.splice(indexProduct, 1)
      }
    },
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async submitData() {
      try {
        const result = await this.$validator.validateAll()
        if (result) {
          this.progress = true
          // Calculate total
          const total = this.total
          const subTotal = parseFloat((this.total / 1.18).toFixed(this.document.roundingType))
          const igv = parseFloat((total - subTotal).toFixed(this.document.roundingType))
          // Get sequence
          let code = ''
          let sequence = ''
          let serie = ''
          if (this.document.client.typeDocument === 'RUC') {
            const response = await axios.get('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/sequences-getSequencesForNotesInvoices', {
              headers: {
                'Authorization': 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
              }
            })
            code = 'FFF1-' + response.data.sequence
            sequence = response.data.sequence
            serie = 'FFF1'
          } else {
            const response = await axios.get('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/sequences-getSequencesForNotesTickets', {
              headers: {
                'Authorization': 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
              }
            })
            code = 'BBB1-' + response.data.sequence
            sequence = response.data.sequence
            serie = 'BBB1'
          }
          // End
          // Get exchange rate
          const responseExchange = await axios.get('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/exchangeRate-getExchangeRate', {
            headers: {
              'Authorization': 'Bearer 78b1deb4-1b27-4943-9d83-818285cc59d6'
            }
          })
          // End
          let obj = {
            ...this.document,
            state: true,
            status: 'hold',
            products: [
              ...this.addedProducts
            ],
            total,
            subTotal,
            igv,
            user: {
              displayName: auth.currentUser.displayName,
              uid: auth.currentUser.uid
            },
            code,
            type: 'regular',
            sequence,
            serie,
            exchangeRate: responseExchange.data.venta + 0.025
          }
          // Save in firestore
          const doc = await db.collection('credits').add({
            ...obj,
            createdAt: FieldValue.serverTimestamp()
          })
          obj.id = doc.id
          obj.createdAt = new Date()

          // Send to nubefact
          const responseNubefact = await this.sendNubefact(sequence, total, subTotal, igv, serie)
          if (responseNubefact !== -1) {
            await db.collection('credits').doc(doc.id).update({
              nubefact: {
                ...responseNubefact.nubefact
              },
              status: 'send'
            })
            // Open PDF in browser
            window.open(responseNubefact.nubefact.enlace_del_pdf, 'blank')
            obj.status = 'send'
          }
          obj.nubefact = {
            ...responseNubefact.nubefact
          }
          // End

          this.$emit('closeSidebar')
          this.$emit('add', obj)
          this.$vs.notify({
            color: 'success',
            title: 'Nota de crédito',
            text: 'Nota de crédito creada correctamente.'
          })
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Search invoice sidebar
     */
    async addInvoiceSidebar(o) {
      try {
        this.showInvoiceSidebar = false
        this.progress = true
        this.addedProducts = []
        this.document.invoice = _.cloneDeep(o)
        this.document.currency = this.document.invoice.currency
        this.document.client = this.document.invoice.client
        this.document.roundingType = this.document.invoice.roundingType
        this.document.paymentMethod = this.document.invoice.paymentMethod
        this.document.igvType = this.document.invoice.igvType
        this.document.regimeType = this.document.invoice.regimeType
        if (this.document.invoice.products.length !== 0) {
          this.document.invoice.products.forEach((p) => {
            const obj = {
              id: p.id,
              sku: p.sku,
              numberPart: p.numberPart,
              price: p.price,
              quantity: p.quantity,
              max: p.quantity,
              description: p.description
            }
            this.addedProducts.push(obj)
          })
        } else {
          const obj = {
            id: 'AOP',
            sku: 'AOP',
            numberPart: 'AOP',
            price: o.subTotal,
            quantity: 1,
            max: 1,
            description: 'Anulación de operación'
          }
          this.addedProducts.push(obj)
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Calculate total
     */
    calculateTotal() {
      this.total = 0
      this.addedProducts.forEach((p) => {
        if (this.document.igvType === 'No incluye IGV') {
          this.total += parseFloat((p.quantity * p.price * 1.18).toFixed(2))
        } else {
          this.total += parseFloat((p.quantity * p.price * 1.18).toFixed(2))
        }
      })
      this.total = parseFloat(this.total.toFixed(2))
    },
    /**
     * Send nubefact
     */
    async sendNubefact(sequence, total, subTotal, igv, serie) {
      const items = []
      this.addedProducts.forEach((p) => {
        items.push(
          {
            'unidad_de_medida': 'NIU',
            'codigo': p.numberPart,
            'codigo_producto_sunat': '32130000',
            'descripcion': p.description,
            'cantidad': p.quantity,
            'valor_unitario': p.price,
            'precio_unitario': parseFloat((p.price * 1.18).toFixed(this.document.roundingType)),
            'descuento': '',
            'subtotal': parseFloat((p.price * p.quantity).toFixed(this.document.roundingType)),
            'tipo_de_igv': 1,
            'igv': parseFloat((p.price * p.quantity * 1.18).toFixed(this.document.roundingType)) - parseFloat((p.price * p.quantity).toFixed(this.document.roundingType)),
            'total': parseFloat((p.price * p.quantity * 1.18).toFixed(this.document.roundingType)),
            'anticipo_regularizacion': false,
            'anticipo_documento_serie': '',
            'anticipo_documento_numero': ''
          }
        )
      })
      // Split emails
      const emails = this.document.client.email !== '_' ? this.document.client.email.split(',') : []
      // End

      // Get client
      const clientDoc = await db.collection('clients').doc(this.document.client.id).get()

      // End

      const document = {
        'operacion': 'generar_comprobante',
        'tipo_de_nota_de_credito': this.document.type.id,
        'documento_que_se_modifica_tipo': this.document.client.typeDocument === 'RUC' ? 1 : 2,
        'documento_que_se_modifica_serie': this.document.invoice.serie,
        'documento_que_se_modifica_numero': this.document.invoice.sequence,
        'tipo_de_comprobante': 3,
        'serie': serie,
        'numero': sequence,
        'sunat_transaction': 1,
        'cliente_tipo_de_documento': this.document.client.typeDocument === 'RUC' ? '6' : '1',
        'cliente_numero_de_documento': this.document.client.document + '',
        'cliente_denominacion': this.document.client.businessName,
        'cliente_direccion': clientDoc.data().address + '-' + clientDoc.data().department + '-' + clientDoc.data().province + '-' + clientDoc.data().district,
        'fecha_de_emision': new Date().getDate() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getFullYear(),
        'moneda': this.document.currency.id,
        'porcentaje_de_igv': 18,
        'total_gravada': subTotal,
        'total_igv': igv,
        'total': total,
        'observaciones': this.document.observation,
        'enviar_automaticamente_a_la_sunat': true,
        'enviar_automaticamente_al_cliente': true,
        'formato_de_pdf': '',
        'tipo_de_cambio': 3.9,
        'cliente_email': emails.length === 1 ? emails[0].trim() : '',
        'cliente_email_1': emails.length === 2 ? emails[1].trim() : '',
        'cliente_email_2': emails.length === 3 ? emails[2].trim() : '',
      }
      const response = await this.$http.post('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/nubefact-sendInvoices', {
          document,
          items
        }, {
          headers: {
            'Authorization': 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
          }
        })
      return response.data
    }
  }
}
</script>

<style lang="scss" scoped>

.add-new-client-header {
  padding: 8px;
  color: green;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    height: 15px;
    margin-right: 4px;
  }

  &:hover {
    background-color: rgba(green, 0.12);
  }
}

.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 80vw;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
